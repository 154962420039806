<template>
  <baseList :breadStaticData="breadStaticData" pageName="基地地块/栏舍信息"></baseList>
</template>
<script>
import baseList from './baseList.vue'
export default {
  name: "baseLists",
  components: {
    baseList,
  },
  props:{
  },
  data(){
    return {
      breadStaticData: ['主体信息', '基地地块/栏舍信息']
    }
  },
  created() {
	},
  mounted() {
  },
}
</script>